const ImageChild = () => {
  return (
    <div classname="w-full h-full">
      <img src="" alt="" />
    </div>

  );
}

export default ImageChild;
